/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useCurrentUserContext } from 'context/current-user-context';
import { getV3Scopes } from 'helpers/nylasClient/v3.scopes';
import { useNylasContext } from '../../helpers/nylasClient/nylas-context';
import { useClient } from '../../context/client-context';
import Banner from '../screen/Banner';
import useAuthCalendar from '../../helpers/nylasClient/useAuthCalendar';

const CalendarlConnectionFail = () => {
  const client = useClient();

  const nylas = useNylasContext();

  const { calendarAuthFailed, calendarOwner } = useCurrentUserContext();

  const { mutate } = useAuthCalendar();

  const [authorizationCode, setAuthorizationCode] = useState<string | undefined>();

  const { buildAuthorizeUrl, connectWithPopup, getCodeParam } = nylas;

  const loginHint = calendarOwner;

  const messageHandler = useCallback(
    (event: MessageEvent<WindowProxy>) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data: message } = event;

      if (event?.source && (event.source as Window).name === 'nylas-connect') {
        const response = getCodeParam(message);
        if (response) {
          const { code, reason } = response;
          if (reason) {
            toast(`An error occurred: ${reason}`, {
              position: 'top-center',
              theme: 'dark',
              autoClose: 3000,
            });
            return;
          }
          if (code) {
            setAuthorizationCode(code);
          }
        }
        window.removeEventListener('message', messageHandler, false);
      }
    },
    [getCodeParam],
  );

  React.useEffect(() => {
    if (authorizationCode) {
      mutate(authorizationCode, {
        onSuccess: () => {
          setAuthorizationCode(undefined);
          toast('Calendar connected', { position: 'top-center', theme: 'dark', autoClose: 3000 });
        },
        onError: (e) => {
          setAuthorizationCode(undefined);
          toast(e.detail, { position: 'top-center', theme: 'dark', autoClose: 3000 });
        },
      });
    }
  }, [authorizationCode, mutate, setAuthorizationCode]);

  React.useEffect(
    () => () => {
      window.removeEventListener('message', messageHandler, false);
    },
    [messageHandler],
  );

  const connect = () => {
    const defaultProvider = client.state?.forceProvider;

    const scope = getV3Scopes(defaultProvider as string | undefined);

    const url = buildAuthorizeUrl({
      // If no forceProvider then the Nylas Hosted dialog will handle the provider selection
      provider: defaultProvider as string | undefined,
      // Omit login_hint to handle case where provider and email domain
      // does not match, i.e. an icoud calendar using a gmail login
      // login_hint: loginHint,
      redirect_uri: `${window.location.origin}/authorize`,
      scopes: scope?.calendar,
    });

    connectWithPopup(messageHandler, url);
  };

  return (
    <Banner
      appearance="warning100"
      isOpen={calendarAuthFailed === true}
      icon={<FontAwesomeIcon size="1x" icon={regular('flag')} />}
      title="Problem Accessing Calendar."
    >
      <Button type="button" size="mini" onClick={connect}>
        Reconnect
      </Button>
    </Banner>
  );
};

export default CalendarlConnectionFail;
