import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { openPopupWindow } from '../openPopup';
import { AuthUrlOptions, NylasClient, NylasContext as Context } from './types';

const createParams = (params: any) =>
  Object.keys(params)
    .filter((k) => typeof params[k] !== 'undefined' && !!params[k])
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

const NylasContext = createContext<Context | undefined>(undefined);

function NylasProvider({ children }: PropsWithChildren) {
  const location = useLocation();

  const navigate = useNavigate();

  const [authCode, setAuthCode] = useState<string | undefined>();

  const [client] = useState<NylasClient>({
    url: 'https://api.nylas.com/oauth/authorize',
    client_id: window.env.APP_NYLAS_CLIENT,
    redirect_uri: window.location.origin,
    response_type: 'code',
  });

  const buildAuthorizeUrl = useCallback(
    (options?: AuthUrlOptions) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { client_id, response_type, redirect_uri } = client;
      const params = {
        client_id,
        response_type,
        redirect_uri,
        ...options,
      };
      const qs = createParams(params);
      const url = `${client.url}?${qs}`;
      return url;
    },
    [client],
  );

  const connectWithRedirect = useCallback((url: string) => {
    window.location.assign(url);
  }, []);

  const connectWithPopup = useCallback((messageHandler: any, url: string) => {
    console.log('[authorize url] ', url);
    openPopupWindow(url, 'nylas-connect', messageHandler);
  }, []);

  const getCodeParam = (params: string) => {
    if (typeof params !== 'string') return undefined;
    const { code, reason } = queryString.parse(params);
    return { code, reason };
  };

  const handleRedirectCallback = (value: string) => {
    // if (!isCalendarConnected) {
    setAuthCode(value);
    // }
  };

  const handleError = () => {
    navigate(location.pathname, { replace: true });
  };

  const value = {
    buildAuthorizeUrl,
    connectWithRedirect,
    connectWithPopup,
    getCodeParam,
    authCode,
    setAuthCode,
    handleRedirectCallback,
    handleError,
  };

  return <NylasContext.Provider value={value}>{children}</NylasContext.Provider>;
}

function useNylasContext() {
  const nylas = useContext(NylasContext);

  if (!nylas) {
    throw new Error('useClient: No value provide or not called within a ClientProvider');
  }

  return nylas;
}

export { NylasProvider, NylasContext, useNylasContext };
