const getV3Scopes = (provider?: string) => {
  switch (provider) {
    case 'google':
    case 'gmail': {
      return {
        calendar: 'https://www.googleapis.com/auth/calendar',
        email: 'https://www.googleapis.com/auth/modify',
        providerName: 'google',
      };
    }
    case 'microsoft':
    case 'exchange':
    case 'office365': {
      return {
        calendar: 'https://graph.microsoft.com/Calendars.ReadWrite',
        email: 'https://graph.microsoft.com/Mail.ReadWrite.Shared',
        providerName: 'microsoft',
      };
    }
    default:
      return undefined;
  }
};

// gmail, exchange and office365 are old v2 Nylas scopes
export { getV3Scopes };
