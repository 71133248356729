import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Input, SIZE } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { ParagraphSmall } from 'baseui/typography';
import { usePopupWindow } from 'helpers/usePopupWindow';
import { getV3Scopes } from 'helpers/nylasClient/v3.scopes';
import { Controller, useFormContext } from 'react-hook-form';
import ControlledCheckbox from 'components/team/components/ControlledCheckbox';
// import useGetSavedEmailAddresses from 'components/team/hooks/useGetSavedEmailAddresses';
import { useClient } from '../../../context/client-context';
import { useNylasContext } from '../../../helpers/nylasClient/nylas-context';
import RequiredFieldMarker from '../../../components/shared/RequiredFieldMarker';

type Props = {
  dispatchNextStep: () => void;
  emailDomain?: string | null;
  formRef?: string;
  getTeamName: () => string;
  onSubmit: (d: any) => any;
  setIsProcessing: (value: any) => void;
  validate: (value: string, name: string) => any;
};

const ProvisionEmailForm = (props: Props) => {
  const { buildAuthorizeUrl, getCodeParam } = useNylasContext();

  // const savedEmails = useGetSavedEmailAddresses();

  const {
    dispatchNextStep,
    emailDomain: domain,
    formRef,
    getTeamName,
    onSubmit,
    validate,
    setIsProcessing,
  } = props;

  const [css, theme] = useStyletron();

  const { state: clientState } = useClient();

  const emailDomain = domain || clientState.emailDomain;

  const [failReason, setFailReason] = useState<string | undefined>(undefined);

  const { openPopupWindow, isOpen } = usePopupWindow();

  const clientEmailSuffix = `@${emailDomain}`;

  const formMethods = useFormContext();

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    register,
    getValues,
    setValue,
    watch,
    reset,
  } = formMethods;

  const defaultEmail = getTeamName();

  const [tokenCode$, hasTeamCalendar$, calendarSameAsEmail$] = watch([
    'tokenCode',
    'hasTeamCalendar',
    'calendarSameAsEmail',
  ]);

  const isEmailTouched = touchedFields?.email;

  const isExternalEmailHost = clientState?.emailHosting === 'EXTERNAL';

  const messageHandler = React.useCallback(
    (event: MessageEvent<WindowProxy>) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      const { data: message } = event;

      if (event?.source && (event.source as Window).name === 'nylas-connect') {
        const response = getCodeParam(message);

        if (response) {
          const { code, reason } = response;
          setValue('tokenCode', code);
          clearErrors(['email', 'tokenCode']);
          // TODO
          dispatchNextStep();
          console.log('done');
          if (reason) {
            setFailReason(reason);
            return;
          }
        }

        window.removeEventListener('message', messageHandler, false);
      }
    },
    [getCodeParam, clearErrors, dispatchNextStep, setValue],
  );

  const nylasConnect = () => {
    const v = getValues('email');

    const defaultProvider = clientState.forceProvider;

    const scope = getV3Scopes(defaultProvider as string | undefined);

    const authScopes = [scope?.email];

    if (calendarSameAsEmail$) {
      authScopes.push(scope?.calendar);
    }

    const url = buildAuthorizeUrl({
      login_hint: v + clientEmailSuffix,
      provider: defaultProvider, // scope?.providerName,
      redirect_uri: `${window.location.origin}/authorize`,
      scopes: calendarSameAsEmail$ ? 'email,calendar' : 'email', //  authScopes.filter((s) => s).join(''),
    });
    openPopupWindow(url, 'nylas-connect', messageHandler);
  };

  const interceptSubmit = (values: any) => {
    if (isExternalEmailHost) {
      const code = getValues('tokenCode');
      if (!code) {
        nylasConnect();
        return;
      }
    }
    onSubmit(values);
  };

  const resetForm = () => {
    reset({
      ...getValues(),
      email: '',
      emailHosting: clientState?.emailHosting,
      tokenCode: undefined,
    });
  };

  useEffect(() => {
    register('tokenCode');
    register('emailHosting', { value: clientState?.emailHosting });
  }, [clientState?.emailHosting, register]);

  useEffect(() => {
    if (defaultEmail && !isEmailTouched && clientState?.emailHosting !== 'EXTERNAL') {
      setValue('email', defaultEmail, { shouldDirty: true });
    }
  }, [clientState?.isBroker, defaultEmail, isEmailTouched, setValue, clientState?.emailHosting]);

  useEffect(() => {
    if (setIsProcessing) {
      setIsProcessing(isOpen);
    }
  }, [isOpen, setIsProcessing]);

  return (
    <form onSubmit={handleSubmit(interceptSubmit)} id={formRef}>
      {isExternalEmailHost ? (
        <>
          <ParagraphSmall color="mono800" marginBottom="scale600">
            To start capturing leads, a default campaign will be created for your team.
          </ParagraphSmall>
          <ParagraphSmall color="mono800" marginBottom="scale600">
            Your organization uses external email accounts, a pop-up window will prompt you to login
            to your provider to link the account. Make sure to allow pop-up windows for SpaceIt in
            your browser preferences.
          </ParagraphSmall>
        </>
      ) : (
        <Block font="ParagraphSmall" color="mono800" marginBottom="scale600">
          To start capturing leads, a default campaign will be created for your team.
        </Block>
      )}
      {failReason && <ParagraphSmall>{failReason}</ParagraphSmall>}
      {isExternalEmailHost ? (
        /* Connect external email provider */
        <Controller
          name="email"
          control={control}
          render={({ field: { value, ref, onChange, onBlur } }) => (
            <FormControl
              label={<RequiredFieldMarker>Email</RequiredFieldMarker>}
              error={(errors?.email?.message || errors.tokenCode?.message) as string | undefined}
              caption={
                tokenCode$ ? (
                  <>
                    <span className={css({ fontWeight: 500, color: theme.colors.positive400 })}>
                      Email Authorized.
                    </span>{' '}
                    To make changes{' '}
                    <Button size="mini" kind="secondary" onClick={resetForm}>
                      Clear Form
                    </Button>
                  </>
                ) : (
                  ''
                )
              }
              overrides={{
                Label: {
                  component: ({ children }: any) => (
                    <div
                      className={css({
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        ...theme.typography.ParagraphSmall,
                      })}
                    >
                      <label
                        htmlFor="email"
                        className={css({
                          color: theme.colors.black,
                          ...theme.typography.LabelSmall,
                          display: 'block',
                          marginTop: '8px',
                          marginBottom: '8px',
                        })}
                      >
                        {children}
                      </label>
                    </div>
                  ),
                },
              }}
            >
              <Input
                type="text"
                value={value}
                disabled={!!tokenCode$}
                inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
                onBlur={onBlur}
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (typeof validate === 'function') {
                    validate(`${e.target.value}`, 'email');
                  }
                }}
                size={SIZE.compact}
                error={!!(errors.email || errors.tokenCode?.message)}
                endEnhancer={() => (
                  <>
                    <span
                      className={css({ marginRight: theme.sizing.scale400, cursor: 'not-allowed' })}
                    >
                      {clientEmailSuffix}
                    </span>
                  </>
                )}
                overrides={{
                  Root: {
                    style: () => ({
                      paddingRight: 0,
                    }),
                  },
                  InputContainer: {
                    style: () => ({
                      flex: '1 1',
                      // flex: '1 1 50%',
                      paddingRight: 0,
                    }),
                  },
                  EndEnhancer: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.mono500,
                      flex: '0 0 auto',
                      // flex: '1 1 50%',
                      paddingRight: $theme.sizing.scale0,
                      paddingLeft: $theme.sizing.scale0,
                      wordBreak: 'break-all',
                      justifyContent: 'flex-end',
                      backgroundColor: $theme.colors.mono100,
                    }),
                  },
                }}
              />
            </FormControl>
          )}
        />
      ) : (
        /* Internal spaceit domain email */
        <Controller
          name="email"
          control={control}
          render={({ field: { value, ref, onChange, onBlur } }) => (
            <FormControl
              label={<RequiredFieldMarker>Email</RequiredFieldMarker>}
              error={errors?.email?.message as string | undefined}
              overrides={{
                Label: {
                  component: ({ children }: any) => (
                    <div
                      className={css({
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        ...theme.typography.ParagraphSmall,
                      })}
                    >
                      <label
                        htmlFor="email"
                        className={css({
                          color: theme.colors.black,
                          ...theme.typography.LabelSmall,
                          display: 'block',
                          marginTop: '8px',
                          marginBottom: '8px',
                        })}
                      >
                        {children}
                      </label>
                    </div>
                  ),
                },
              }}
            >
              <Input
                type="text"
                value={value}
                inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
                onBlur={onBlur}
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (typeof validate === 'function') {
                    validate(`${e.target.value}`, 'email');
                  }
                }}
                size={SIZE.compact}
                error={!!errors.email}
                endEnhancer={() => <span>{clientEmailSuffix}</span>}
                overrides={{
                  InputContainer: {
                    style: () => ({
                      flex: '1 1 50%',
                    }),
                  },
                  EndEnhancer: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.mono500,
                      flex: '1 1 50%',
                      // whiteSpace: 'nowrap',
                      wordBreak: 'break-all',
                      marginRight: '-14px',
                      cursor: 'not-allowed',
                      justifyContent: 'flex-start',
                      backgroundColor: $theme.colors.mono100,
                    }),
                  },
                }}
              />
            </FormControl>
          )}
        />
      )}

      <Controller
        name="emailType"
        control={control}
        render={({ field: { value, onChange } }) => (
          <input type="hidden" value={value} onChange={onChange} />
        )}
      />

      {clientState?.calendarScope === 'TEAM' && (
        <>
          <FormControl>
            <ControlledCheckbox
              control={control}
              name="hasTeamCalendar"
              label="Connect a team calendar"
              baseWebProps={{
                disabled: !!tokenCode$,
              }}
              handleOnChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (!e.target.checked) {
                  setValue('calendarSameAsEmail', false);
                }
              }}
            />
          </FormControl>

          {isExternalEmailHost && hasTeamCalendar$ && (
            <FormControl>
              <ControlledCheckbox
                control={control}
                name="calendarSameAsEmail"
                label="Team calendar account same as email"
                baseWebProps={{
                  disabled: !!tokenCode$,
                }}
              />
            </FormControl>
          )}
        </>
      )}
    </form>
  );
};

export default ProvisionEmailForm;
